<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="">
            <h3>Total de servicios</h3> <small> (Hay {{ totalServices }} servicios)</small>
          </div>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="7"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar por nombre"
            />
            <v-select
              v-model="filterQueryStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="productStatusOptions"
              class="w-100 mr-1"
              :reduce="val => val.value"
              placeholder="Filtrar por estado..."
            />
            <b-button
              variant="primary"
              class="text-nowrap"
              @click="$router.push({ name: 'service-create'})"
            >
              Agregar servicio
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refServicesListTable"
      :items="fetchServices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidentes"
      class="position-relative"
    >
      <!-- Column: Id -->

      <!-- Column: Service status -->
      <template #cell(status_name)="data">
        <b-badge
          pill
          :variant="`light-${resolveStatusVariant(data.item.status_name)}`"
          class="text-capitalize"
        >
          {{ data.item.status_name }}
        </b-badge>
      </template>

      <!-- Column: Service status -->
      <template #cell(name)="data">
        <b-link
          :to="{ name: 'service-edit', params: { id: data.item.id }}"
          class="alert-link font-weight-bold d-block text-nowrap"
        >
          {{ data.item.name }}
        </b-link>
      </template>

      <template #cell(description_quotation)="data">
        <span
          class="text-wrap"
          v-html="data.item.description_quotation"
        />
      </template>

      <!-- Column: Photo -->
      <template #cell(photo)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-img
              v-if="data.item.photo"
              rounded
              thumbnail
              fluid
              width="200"
              :src="data.item.photo"
              alt="Photo dramox"
            />
          </template>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">

        <div class="text-nowrap">

          <feather-icon
            :id="`service-row-${data.item.id}-edit-icon`"
            icon="Edit2Icon"
            size="16"
            class="mx-1 cursor-pointer"
            @click="$router.push({ name: 'service-edit', params: { id: data.item.id }})"
          />
          <b-tooltip
            title="Editar"
            :target="`service-row-${data.item.id}-edit-icon`"
          />

          <span v-can-permissions="'Editar servicio'">
            <feather-icon
              :id="`service-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer"
              @click="deleteService(data.item)"
            />
            <b-tooltip
              title="Eliminar"
              :target="`service-row-${data.item.id}-delete-icon`"
            />
          </span>

        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalServices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput, BLink,
  BMedia, BPagination,
  BRow,
  BTable, BTooltip, BImg,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import serviceStoreModule from '@/views/dramox/services/serviceStoreModule'
import serviceHandler from '@/views/dramox/services/serviceHandler'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'HomeBusiness',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    // BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BTooltip,
    BLink,
    BBadge,
    BButton,
    vSelect,
    BImg,
  },
  data() {
    return {
    }
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-services'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, serviceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }

    const productStatusOptions = [
      { label: 'TODOS', value: '' },
      { label: 'DESACTIVADO', value: '0' },
      { label: 'PUBLICO', value: '1' },
      { label: 'PRIVADO', value: '2' },
    ]

    const {
      tableColumns,
      currentPage,
      totalServices,
      perPage,

      searchQuery,
      filterQueryStatus,
      filterQueryCategory,

      refServicesListTable,
      refetchData,
      fetchServices,

      resolveStatusVariant,
      resolveStockVariant,
    } = serviceHandler()

    const deleteService = val => {
      store.dispatch('app-services/deleteService', { id: val.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Eliminado con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      // businessObject,
      // resetBusinessData,
      fetchServices,

      tableColumns,
      currentPage,
      totalServices,

      searchQuery,
      filterQueryStatus,
      filterQueryCategory,

      perPage,
      refServicesListTable,
      refetchData,
      avatarText,
      resolveStatusVariant,
      resolveStockVariant,
      productStatusOptions,

      deleteService,
      // businessData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
