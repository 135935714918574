import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function serviceHandler() {
  // Use toast
  const toast = useToast()

  const refServicesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'photo', label: 'Foto', sortable: false },
    { key: 'name', label: 'Nombre', sortable: false },
    { key: 'price_pen', label: 'Precio (s/)', sortable: false },
    { key: 'price_usd', label: 'Precio ($)', sortable: false },
    { key: 'status_name', label: 'Estado', sortable: false },
    { key: 'updated_at', label: 'F.Actualizacion', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalServices = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const filterQueryStatus = ref(null)
  const filterQueryCategory = ref('')

  const refetchData = () => {
    refServicesListTable.value.refresh()
  }

  watch([currentPage, searchQuery, filterQueryStatus, filterQueryCategory], () => {
    refetchData()
  })

  const fetchServices = (ctx, callback) => {
    store
      .dispatch('app-services/fetchServices', {
        searchText: searchQuery.value,
        category: filterQueryCategory.value,
        status: filterQueryStatus.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, total } = response.data.payload.results

        callback(data)
        totalServices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching quotations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  const resolveStatusVariant = status => {
    if (status === 'PUBLICO') return 'success'
    if (status === 'PRIVADO') return 'info'
    if (status === 'DESACTIVADO') return 'warning'
    return 'primary'
  }
  const resolveStockVariant = stock => (stock > 0 ? 'success' : 'danger')

  onMounted(() => {

  })

  return {
    fetchServices,

    tableColumns,
    perPage,
    currentPage,
    totalServices,

    searchQuery,
    filterQueryStatus,
    filterQueryCategory,

    refServicesListTable,
    refetchData,
    resolveStatusVariant,
    resolveStockVariant,
  }
}
